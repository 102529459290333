
// .history-sec-box {
//     height: calc(100vh - 200px);
//     overflow-y: scroll;
//     }
    .list-box{
      padding: 0;
      margin: 0;
    }
    
    
    .history-sec-box .list-box li{
        position: relative;
        padding: 20px 0;
    }
    .history-sec-box .list-box li:before{
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: .5px;
      background-color: #BABABA;
      left: 0;
      transform: rotate(-0deg);
    }
        
    .history-box{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .history-box .history-detail{
      text-align: left;
      width: calc(100% - 60px);
    }
    .history-box .history-detail .history-date{
        font-size: 12px;
        color: #BABABA;
        font-weight: 600;
        margin-bottom: 0;
    }
    .history-box .history-detail .history-title{
        font-size: 12px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 0;
    }
    .history-box .history-detail .history-order{
        font-size: 12px;
        font-weight: 600;
        color: #BABABA;
        margin-bottom: 0;
    }
    .history-box .history-price{
      text-align: right;
       width: 80px;
    }
    .history-box .history-price .price-detail{
      color: #000;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
      
    }
    .history-box .history-price .history-msg{
      color: #BABABA;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 0;
    }
    .history-box .cart-img{
        width: 40px;
        text-align: left;
    }

    input.form-control.input-number {
      padding: 0 10px;
      text-align: center;
      height: 30px;
      font-size: 13px;
  }
  .form-control:disabled{
    color: #e3cbcb !important;
    background: transparent;
  }
  button.btn.bgRed.btn-number {
      padding: 0;
      width: 20px;
      line-height: 1;
      height: 20px;
      color: white;
      opacity: 1;
  }
  .form-check{padding-left: 0;}
  .history-detail .form-check input {
    position: absolute;
    left: -65px;
}

.history-box {
  position: relative;
}

.history-box label {
  background-color: #BABABA;
  border: 1px solid #BABABA;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: -80px;
  position: absolute;
  top: -15px;
  width: 20px;
  span{
    position: absolute;
    padding-left: 79px;
    top: -19px;
    width: calc(100vw - 150px);
  }
}

.history-box label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 12px;
}

.history-box input[type="checkbox"] {
  visibility: hidden;
}

.history-box input[type="checkbox"]:checked + label {
  background-color: #F40000;
  border-color: #F40000;
}

.history-box input[type="checkbox"]  + label {
  background-color: transparent;
  border-color: #F40000;
}
.history-box input[type="checkbox"]:disabled   + label {
  background-color: #BABABA;
  border-color: #bababa;
}
.history-box input[type="checkbox"] + label:after {
  opacity: 1;
}

.history-box input[type="checkbox"]:checked + label:after {
  opacity: 1;
}