.footer-nav{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #000;
    z-index: 1000;
    table{
        width: 100%;
    }
    td{
        text-align: center;
        // width: 33%;
        img{
            height: 30px;
        }
        p{
            color: #fff;
            margin-bottom: 0;
        }
    }
}
@media screen and (max-width:992px) {
    
}
@media screen and (max-width:767px) {
    
}