@import "../../assets/scss/variables";
.blankbox{
    height: 80px;
}
.alertbox{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    img{
        margin-right: 20px;
    }
    p{
        margin-bottom: 0;
        color: $red;
        font-size: 14px;
    }
}

.circle-imgbox{
    height: calc(100vh - 246px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    .whitecircle{
        
        img{
            display: flex;
            justify-content: center;
            align-items:center;
            height: 100%;
            width: 100%;
            margin: 0 auto;
        }
    }
}
.Doorunlock-box{
    p{
        color: $black;
        font-size: 20px;
        margin-bottom: 0;
    }
}