body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#container-circles {
  top: 300px;
  left: 150px;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: rgb(255 0 0 / 50%) 0px 0px 0px 5px inset;
  position: absolute;
}

#outer-circle{
  position: absolute;
  /* left: 57px; */
  height: 75px;
  width: 75px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  z-index: 1;
}

#inner-circle {
  position: absolute;
  left: 50%;
  top: 38px;
  height: 44px;
  width: 44px;
  background: white;
  border-radius: 50%;
  margin: -22px 0px 0px -22px;
  z-index: 2;
}

.react-html5-camera-photo video{
  top: 0px;
  left: 0px; 
  display: block;
   position: absolute;
    overflow: hidden; 
    width: 100%; 
    height: 100%; 
    object-fit: cover;
}

.test1{
  top: 0px; left: 0px; z-index: 1; box-sizing: border-box; border: 50px solid rgba(0, 0, 0, 0.3); box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset; position: absolute; width: 100%; height: 100%;
}