@import "../../assets/scss/_variables.scss";


.changesize{padding: 30px 20px 50px; margin-top: 30px;}
span.input-group-text {
    background: none;
    border: none;
}

input.form-control {
    border: none;
    background: none;
}
.LineInput{
    .input-group{
        position: relative;
        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: .5px;
            background-color: $grey;
            left: 0;
            transform: rotate(0deg);
        }
    }
}