.loader{
    text-align: center;
    height: 100%;
    .loaderImage{
        margin: 0 auto;
        height: 90%;
        padding: 7em 2em;
    }
    .loaderText{
        margin: 0 auto;
        height: 10%;
    }
}

.loader{display: none;}