@import "../../assets/scss/variables";

.support-box {
    .list-line {
        padding-left: 0;
        li{
            border-bottom: 1px solid #aaa;
            padding: 20px 0;
            list-style: none;
            font-weight: 500;
            font-size: 16px;
            position: relative;
            color: #000;
            &::after{
              content: '';
              height: 25px;
              width: 25px;
              background: url(../../_assets/images/icons/black_arrow.png);
              top: 25px;
              background-repeat: no-repeat;
              position: absolute;
              right: 5px;
            }
            a{
                color: $black;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                display: block;
                display: flex;
                justify-content: space-between;
                .time-text{
                    color: $grey;
                  }
              }
              &:hover a{
                color:$red;
                text-decoration: none;
              }
        }
    }
}
.bottomline{
    position: relative;
    padding: 20px 0 30px 0;
    &:before{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: .5px;
        background-color: $grey;
        transform: rotate(0deg);
      }
    &:after{
        content: '';
                position: absolute;
                bottom: 0px;
                right: 0;
                width: 5px;
                height: 5px;
                background-color: #BABABA;
                border-radius: 50%;
    }
  }
