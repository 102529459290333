.offer{
    margin-top: 15px;
    h4{
        font-size: 15px;
        font-weight: bold;
        color: #000;
    }
    .offerDetail{
        padding: 15px;
        padding-left: 30px;
        position: relative;
        p{
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 5px;
            small{
                font-weight: bold;
            }
        }
    }
    .offerDetail::after{
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        min-height: 100px;
        height: 100%;
        background: linear-gradient(90deg, #F40000 0%, #FF5A00 19.79%, #FF8700 35.02%, #FFB400 50%, #78E6A0 80.73%, #82E1FF 100%);
        border-radius: 60px 0px 0px 60px;
        z-index: -1;
        
    }
}
.image_right_product{
    position: absolute;
    right: 5px;
    top: -7px;
    border-radius: 10px;
    width: 130px;
    max-height: 130px;
}
.left70{ width:60%;}
    // .{position: relative;}