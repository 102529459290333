@import "./../../assets/scss/variables";

.whitebody-popup{
    background: $white;
    box-shadow: 0 25px 50px rgba(0,0,0,0.1);
    padding: 20px;
    border-radius: 5px;
}

.popup-box{
    text-align: center;
    // max-width: 500px;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.1);
        .whitebody-popup{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            min-height: 280px;
            width: 90%;
            .popup-title{
                font-size: 18px;
                margin: 30px 0 50px 0;
                    span{display: block;}
            }
            
            .circle-box{
                margin-top: -100px;
            }
        }
    }
