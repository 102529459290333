.whitebox{
    min-height: 60px;
    max-height: 60px;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.col-8,.col-4{
    padding: 0 5px !important;
}
.whiteboxratings {
    padding: 15px ;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 30px 50px rgba(0,0,0,0.1);
    
    min-height: 60px;
    max-height: 60px;
    overflow: hidden;
    .profileImage{
        display: block;
        float: left;
        img{
            height: 100%;
        }
    }
    .profileName{display: block;float: left;padding-left: 10px;  font-size: 18px; line-height: 1.6em; font-weight: bold;}

    .pointLoader {
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid #f40000;
        width: 25px;
        height: 25px;
        animation: spin 2s linear infinite;
        margin-top: 3px;
        margin-left: 10px;
    }
  
    /* Safari */
    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .refreshpoint{
        display: block;
        float: right;
        img{
            height: 25px;
            cursor: pointer;
        }
    }
    .mobileRefresh{
        display: none;
    }
    @media(max-width:768px) {
        .refreshpoint{
            display: none!important;
        }
        .mobileRefresh{
            display: block!important;
            margin-top: 5px;
        }
        .starIcon{
            display: none!important;
        }
    }
}
