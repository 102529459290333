
.NoLongerBody {
  font: normal 100%/1.15 "Merriweather", serif;
  background-color:#f40000a8;
  color: #fff;
  margin-top: -40px;
 }

 .NoLongerBody .wrapper {
  position: relative;
  max-width: 1298px;
  height: auto;
  margin: 2em auto 0 auto;
}
.wrapper .box {
  max-width: 70%;
  min-height: auto;
  margin: 0 auto;
  padding: 1em 1em;
  text-align: center;
  background: url("https://www.dropbox.com/s/xq0841cp3icnuqd/flame.png?raw=1") no-repeat top 10em center/128px 128px,
              transparent url("https://www.dropbox.com/s/w7qqrcvhlx3pwnf/desktop-pc.png?raw=1") no-repeat top 13em center/128px 128px;
}

.wrapper .box2 {
    max-width: 70%;
    min-height: auto;
    margin: 0 auto;
    padding: 1em 1em;
    text-align: center;
    // background: url("https://www.dropbox.com/s/xq0841cp3icnuqd/flame.png?raw=1") no-repeat top 10em center/128px 128px,
    //             transparent url("https://www.dropbox.com/s/w7qqrcvhlx3pwnf/desktop-pc.png?raw=1") no-repeat top 13em center/128px 128px;
  }
//h1, p:not(:last-of-type) { text-shadow: 0 0 6px #216f79; }

.NoHeading {
  margin: 0 0 1rem 0;
  font-size: 8em;
}

.NoHeading2 {
    margin: 0 0 -8rem 0;
    font-size: 8em;
  }

.Para {
  margin-bottom: 0.5em;
  font-size: 3em;
}

.Para:first-of-type { margin-top: 4em; }

.Para > a {
  border-bottom: 1px dashed #216f79;
  font-style: italic;
  text-decoration: none;
  color: #216f79;
}

//p > a:hover { text-shadow: 0 0 6px #216f79; }

.Para img { vertical-align: bottom; }

@media only screen and (min-width: 600px) {
  .NoLongerBody {
    font: normal 100%/1.15 "Merriweather", serif;
    background-color:#f40000a8;
    color: #fff;
    margin-top: -40px;
   }
  
   .NoLongerBody .wrapper {
    position: relative;
    max-width: 1298px;
    height: auto;
    margin: 2em auto 0 auto;
  }
  .wrapper .box {
    max-width: 70%;
    min-height: auto;
    margin: 0 auto;
    padding: 1em 1em;
    text-align: center;
    background: url("https://www.dropbox.com/s/xq0841cp3icnuqd/flame.png?raw=1") no-repeat top 10em center/128px 128px,
                transparent url("https://www.dropbox.com/s/w7qqrcvhlx3pwnf/desktop-pc.png?raw=1") no-repeat top 13em center/128px 128px;
  }
  
  .wrapper .box2 {
      max-width: 70%;
      min-height: auto;
      margin: 0 auto;
      padding: 1em 1em;
      text-align: center;
      // background: url("https://www.dropbox.com/s/xq0841cp3icnuqd/flame.png?raw=1") no-repeat top 10em center/128px 128px,
      //             transparent url("https://www.dropbox.com/s/w7qqrcvhlx3pwnf/desktop-pc.png?raw=1") no-repeat top 13em center/128px 128px;
    }
  //h1, p:not(:last-of-type) { text-shadow: 0 0 6px #216f79; }
  
  .NoHeading {
    margin: 0 0 1rem 0;
    font-size: 8em;
  }
  
  .NoHeading2 {
      margin: 0 0 1rem 0;
      font-size: 6em;
    }
  
  .Para {
    margin-bottom: 0.5em;
    font-size: 3em;
  }

  .Para2 {
    margin-bottom: 0.5em;
    font-size: 2em;
  }
  
  .Para:first-of-type { margin-top: 4em; }
  
  .Para > a {
    border-bottom: 1px dashed #216f79;
    font-style: italic;
    text-decoration: none;
    color: #216f79;
  }
  
  //p > a:hover { text-shadow: 0 0 6px #216f79; }
  
  .Para img { vertical-align: bottom; }
}

@media screen and (max-width : 870px){
  .NoHeading {
   // margin: 0 0 1rem 0;
    font-size: 4em;
    padding-bottom: 30px;
  }
  
  .Para {
    margin-top: 0.5em;
    font-size: 2em;
  }
  .Para:first-of-type {
    margin-top: 7em;
}
}
