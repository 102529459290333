.tax-wrapper {
    display: flex;
    p{
    font-size: 12px;
    margin-bottom: 0px;
    color: #000;
    font-weight: bold;
    }
    span{
        color: #36C54C;
        display: block;
        font-size: 12px;
        font-weight: bold;
    }
}