@import "./variables.scss";
.main-wrapper{
    height: 100vh!important;
}
.content{
    padding: 1rem;
    .promo-banner{
        min-height: 100px;
        background-size: cover !important;

        .promo-banner-content{
            padding-top: 10px;
            padding-left: 10px;
            width: 100%;
            text-align: left;

            p{
                font-size: 12px;
                font-weight: 700;
                margin-bottom: 5px;
            }

            .details-btn{
                background-color: #000;
                color: white;
                font-size: 10px;
                border-radius: 25px;
                width: 32%;
                text-align: center;
                padding: 9px;
            }
        }
    }
    .main-content{
        height: calc(100vh - 200px);
        overflow-y: scroll;
    }
}
.text-underline{
    text-decoration: underline!important;
}
.bg-dark{
 background-color: #000!important;
}
.h-100{
    height: 100vh;
}
.productimg img{
    max-height: 90px;
}
.theme-btn{
    background-color: #fff;
    // position: absolute;
    // bottom: 30%;
    // left: 50%;
    // transform: translateX(-50%);
    // background: no-repeat;
    border: 2px solid $black;
    width: 240px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    cursor: pointer;
}
.loginwith{    
    color: $white;
    background-color: $black;
    padding: 20px 0;
    min-height: 100vh;
    h3{
        font-size: 33px;
    }
    p  {
        font-size: 13px;
        padding-left: 15px;
    }
    ul {
        margin-top: 5rem;
        list-style: none;
        li {
            margin-bottom: 20px;
            a{
                border-radius: 25px;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
    .login-text{
        text-align: center;
        font-size: 16px;        
        margin: 50px 0 0;
        a{
            color: $white;
        }
    }    
}
.border-radius-none{
    border-radius: 0!important;
}

.scanner{
    max-height: 100vh;
    section{
        height: 100vh!important;
        div{
            height: 350px!important;
            width: 350px!important;
            left: 50%!important;
            top: 50%!important;
            // border-radius: 50%;
            transform: translate(-50%,-50%)!important;
            box-shadow:none!important;
            border:4px solid rgb(255, 255, 255)!important;
        }
    }
}
.scan-btn{
    // background-color: #000;
    padding: 10px;
    border-radius: 25px;
    position: relative;
    background: linear-gradient(90deg,#F40000,#FF5A00,#FF8700,#FFB400,#78E6A0,#82E1FF);
    h5{
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        // display: inline-block;
        // margin-left: 10px;
        // margin-bottom: 0;
        // margin-top: 1px;
        position: absolute;
        left: 55px;
        top: 50%;
        transform: translateY(-50%);
    }
    img{
        margin: 5px;
    }
}
.Editform{
    label{
        font-weight: 500;
        color: #000!important;
    }
    label.text-danger{
        color: #dc3545 !important;
    }
    .form-control{
        border-color: #000!important;
        color: #000!important;
        padding-left: 0;
    }
    .form-control:disabled{
        color: #000!important;
    }
}
.btn-with-border{
    width: 90%;
    border: 2px solid #000!important;
    border-radius: 20px!important;
    font-weight: 500!important;
}
.blackBtn-with-border{
    width: 90%;
    border: 2px solid white!important;
    border-radius: 20px!important;
    font-weight: 500!important;
    color: white !important;
}
.btn-theme-dark{
    width: 90%;
    background-color: #000!important;
    border-radius: 20px!important;
    font-weight: 500!important;
    color: #fff!important;
}
.text-success{
    color: #36C54C!important;
}
.ordersummary_title{
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 5px;
}
.purchaseDate {
    color: #000;
    font-weight: 500;
    margin-bottom: 5px;
}
.orderSummary{
    // width: 100%;
    // padding-bottom: 20px;
    // border-bottom: 1px solid #ccc;
    // overflow-x: scroll;
}
.totalPrice{
    padding: 5px 0;
    display: flex;
    float: right;
    p{
        margin-top: 5px;
        margin-right: 10px;
        font-weight: 600;
        margin-bottom: 0;
    }
    h4{
        font-weight: 600;
    }
}
.coupon_text{
    font-weight: 600;
}
.clearboth{
    clear: both;
}
.payment-status{
    width: 100%;
    font-size: 14px!important;
    border-radius: 20px!important;
    font-weight: bold!important;
    color: #000;
    position: relative;
    margin-bottom: 10px;
    text-align: left!important;
    padding-left: 20px!important;
    z-index: -1;
}
.payment-status.success{
    background: right 3px no-repeat, linear-gradient(90deg, rgba(120,229,161,1) 0%, rgba(130,225,253,1) 100%);
    ::after{
        content: "";
        height: 34px;
        width: 34px;
        position: absolute;
        border-radius: 50%;
        background: url(../images/icons/success.png);
        background-position: center;
        background-color: #000;
        right: 0;
        top: 0;
        background-size: 80%;
    }
}

.payment-status.error{
    color: #fff;
    background: linear-gradient(89.88deg, #FFB400 0.09%, #F40000 99.89%);
    ::after{
        content: "";
        height: 34px;
        width: 34px;
        position: absolute;
        border-radius: 50%;
        background: url(../images/icons/error.png);
        background-position: center;
        background-color: #000;
        right: 0;
        top: 0;
        background-size: 80%;
    }
}
.btn-unlockcooler{
    background-color: #000!important;
    color: #fff!important;
    padding: 15px 10px;
    border-radius: 15px!important;
    min-width: 150px; 
    position: relative;
    text-align: left!important;
    font-weight: 500!important;
    font-size: 1.1rem!important;
    img{
        height: 20px;
        float: right;
        top: 5px;
        right: -5px;
    }
}
.home-subtitle{
    color: #000;
    font-weight: 500;
}
.unlockcooler_btn{
    // background: linear-gradient(270deg, #F40000 0%, #FF5A00 19.13%, #FF8700 36.75%, #FFB400 56.89%, #78E6A0 78.04%, #82E1FF 96.67%);
    background: linear-gradient(270deg, #F40000 0%, #FF5A00 19.4%, #FF8700 37.26%, #FFB400 57.68%, #78E6A0 79.11%, #82E1FF 98%);
    box-shadow: 0px 0px 50px 10px #00000024;
    height: 82px;
    border: none;
    border-radius: 50px 50px 50px 50px;
    text-align: center;
    display: flex;
    align-items: center;
    bottom: 30%;
    position: sticky;
    z-index: 10;
    margin: 0px 15px 0px 15px;
    width: -webkit-fill-available;
    img{
        // float: left;
        height: 60%;
        margin-left: 8%;
        // margin-left:-10px
    }
    span{
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 1.4;
    }
}
.supportText{
    padding: 20px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background: white;
}
// .btn-unlockcooler::after{
//     content: '';
//     height: 22px;
//     width: 22px;
//     background: url(../images/icons/black_arrow.png);
//     background-size: cover;
//     position: absolute;
//     top: 7px;
//     right: 5px;
//     background-repeat: no-repeat;
// }
// .btn-unlockcooler.btn-back::after{
//     left: 5px;
//     right: unset;
//     top: 7px;
//     transform: rotate(180Deg);
// }
.btn-unlockcooler.btn-back{
    text-align: center!important;
    padding-right: 20px!important;
    img{
        height: 20px;
        float: left;
        transform: rotateY(180deg);
        top: 5px;
        left: 0;
    }
}
.ProductList{
    background-color: #000;
    height: 100%;
}
.publicheader .logo{
    height: 90px;
}
// .editProfile-page{
//     height: calc(100vh - 140px);
//     overflow-y: scroll;
// }
.pendingSubTitle{
    font-size: 12px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
}
.card_details{
    margin-top: 15px;
    width: 100%;
    th{
        font-size: 14px;
    }
}
.history_card_details{
    margin-top: 10px;
    width: 100%;
    th{
        font-size: 14px;
        color: #000;
    }
}
.bg-black{
    background-color: #000!important;
    border-radius: 0.5rem!important;
}
.generate_receipt{
    list-style: none;
    padding-left: 0rem;
    margin-top: 1rem;
    li{
        background: #E5E5E5;
        padding: 10px;
        margin-bottom: 10px;
        display: flex;
        input{
            height: 16px;
            width: 16px;
        }
    }
    label{
        padding-left: 1rem;
        margin-bottom: 0;
        margin-top: -4px;
    }

}
.receipt_type{
    margin-top: 1rem;
    min-height: 6rem;
    input.form-control{
        color: #000!important;
        border-radius: 0;
        border-bottom: 1px solid #000!important;
    }
    select.form-control{
        border: none;
        border-bottom: 1px solid #000;
        border-radius: 0;
        padding-left: 5px;
    }
    input.form-control:read-only{
        background-color: transparent;
    }
}
.reward_title{
    background: linear-gradient(90deg, #FFB400 0%, #F40000 100%);
    width: 98%;    
    // position: absolute;
    left: 0;
    margin-top: 25px;
    padding: 1.5rem;
    display: flex;
    border-radius: 0 50px 50px 0;
    .font-size1{
        font-size: 21px;
    }
    .font-size2{
        font-size: 27px;
    }
    h4{
        margin-bottom: 0;
        color: #fff;
        font-size: 27px;
        span{
            font-size: 1rem;
        }
    }
}
.reward_details{    
    padding: 1.5rem;
    margin-top: 15px;

}
.see_rules{
    background: linear-gradient(90deg, #FFB400 0%, #F40000 100%);
    width: fit-content;    
    position: absolute;
    left: 0;
    margin-top: 15px;
    padding: 0.5rem;
    padding-right: 15px;
    display: flex;
    border-radius: 0 50px 50px 0;
    a{
        color: #fff;
    }
}
.reward_section{
    background: black;
    padding: 1rem;
    h5{
        color: #fff;
    }
    ul{
        list-style: none;
        display: table-footer-group;
        text-align: center;
        li{
            display: inline-block;
            height: 2.5rem;
            width: 2.5rem;
            margin-right: 1rem;
            // background: #fff;
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: 600;
            border-radius: 50%;
            padding: .2rem;
            color: #fff;
            border: 2px solid #fff;
        }
    }
}
.fingerprint{
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);

    img{
        height: 70px;
    }
}
.fingerprint.keypad-open{
    position: relative;
    padding: 4rem 0;
    text-align: center;
}
@media screen and (max-width:992px) {
    .custom-slider{
        max-width: 100%;
    }
    .scanner{
        max-height: 100vh;
        section{
            height: 100vh!important;
            div{
                height: 350px!important;
                width: 350px!important;
            }
        }
    }
}
@media screen and (max-width:767px) {
    .productimg img{
        max-height: 80px;
    }
    // .history-sec-box{
    //     height: calc(100vh - 200px)!important;
    // }
    .loginwith{
        min-height: 100vh;
    }
    .scanner{
        max-height: 100vh;
        section{
            height: 100vh!important;
            div{
                height: 275px!important;
                width: 275px!important;
            }
        }
    }
}
#reader{
    height: 100vh;
    width: 100%;
    video{
        height: 100vh!important;
        width: 100%!important;
    }
}

div#qr-shaded-region::after {
    content: "";
    position: absolute;
    height: 300px;
    width: 300px;
    left: 50%;
    top: 51%;
    /* background-color: red; */
    border-radius:50%; 
    padding:10px; 
    background:linear-gradient(45deg,#F40000,#FF5A00,#FF8700,#FFB400,#78E6A0,#82E1FF,#82E1FF ); 
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;    
    transform: translate(-50%,-50%);
}
.payoutstanding{
    font-weight: 400!important;
    position: relative;    
}
.payoutstanding::after{
    content: '';
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
    top: 8px;
    background-image: url(../images/icons/white-arrow.png);
    background-repeat: no-repeat;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
   -webkit-box-shadow: 0 0 0 30px black inset ;    
   box-shadow: 0 0 0 30px black inset ;
   -webkit-text-fill-color: white;  
}

input.addCardInput:-webkit-autofill,
input.addCardInput:-webkit-autofill:hover,
input.addCardInput:-webkit-autofill:focus,
input.addCardInput:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset!important;
    box-shadow: 0 0 0px 1000px #ffffff inset!important;
    -webkit-text-fill-color: #000!important
}

.offerProducts {
        height: 140px;
        margin-top: 8rem;
        position: relative; 
    .offerProductsContent{
        max-width: 100%;
        overflow-x: scroll;
    }
    .product-list{        
        text-align: center;
        position: relative;
        list-style:none;
        display: flex;
        li{
            display: inline;
        }
        img.offerproduct-image{
            height: 140px;
            max-width: 140px;
            margin-top: -5px;
            margin-right: 25px;
        }
    }
}
.offerProducts::before{
    content: "";
    position: absolute;
    top: 25px;
    background: linear-gradient(-90deg, #F40000 0%, #FF5A00 19.79%, #FF8700 35%, #FFB400 58.85%, #78E6A0 80.73%, #82E1FF 100%);
    border-radius: 0px 60px 60px 0px;
    width: 90%;
    height: 110px;
}

.productcontainer{
    width: 100%;
    overflow-x: scroll;
}
.modalbtnFullWidth{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px;
    margin: 0;
    // background: linear-gradient(89.88deg, #FFB400 0.09%, #F40000 99.89%);
    font-size: 18px;
    cursor: pointer;
    .btn{
        border: 1px solid #000000;
        border-radius: 21px;
        font-weight: 700;
        width: 130px;
    }
}
.modalbtnFullWidth.Successbg{
    background: linear-gradient(270deg, #82E1FF 16.29%, #78E6A0 89.57%);
}
.modalbtnFullWidth.Errorbg{
    background: linear-gradient(89.88deg, #FFB400 0.09%, #F40000 99.89%);
}
@media screen and (max-height:540px) {
    .custom-slider .custom-slider-item{
        height: calc(100vh - 200px)!important;
        overflow-y: scroll;
    }
}
.needHelpSubTitle{
    font-family: 'TCCC-UnityText Regular';
    color: #000;
} 
.price_details{
    tr{
        border-top: 1px solid #ccc;
    }
    th,td{
        padding: 5px!important;
        border-top: none;
    }
    .summary_title{
        font-size: 18px;
        font-weight: 600;
    }
    .refund_title{
        color: #F85901;
        font-size: 18px;
        font-weight: 600;
    }
    .text-right{
        font-size: 20px;
        font-weight: bold;
    }
    .summary_total{
        font-size: 14px;
        font-weight: bold;
        margin-right: 5px;
        img{
            height: 14px;
            margin: 3px;
            // margin-top:0px
        }
    }
}
.points{
    font-size: 1.25rem;
    color: #fff;
}
.starcount{
    display: inline-flex;
    padding: 0 0.5rem;    
    
}
.star {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 15px;
    height: 35px;
    width: 35px;
    border: 2px solid #fff;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    color: #fff;
}

.star-with-brand {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 15px;
    height: 35px;
    width: 35px;
    border: 2px solid black;
    border-radius: 50%;
    font-size: 20px;
    text-align: center;
    color: #fff;
}

.starcount:nth-child(5),.starcount:nth-child(10){
    margin-right: 0px;
}

.star img {
    height: 35px;
    margin-top: -5px;
    margin-left: -2px;
}

.star-with-brand img {
    height: 35px;
    max-width: 100%;
    margin-top: -5px;
    margin-left: -2px;
}
.bottle{
    position: relative;
    text-align: center;
}
.bottle::before{
    content: "=";
    position: absolute;
    left: 0%;
    top: 40%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}
.pointdetail{
    height: calc(100vh - 150px);
    // overflow: scroll;
    position: relative;
}
.survey-banner{
    // padding: 10px;
    position: relative;
    h4{
        font-size: 20px!important;
    }
    h3{
        background: -webkit-linear-gradient(180deg, #F40000 0%, #FF5A00 19.13%, #FF8700 36.75%, #FFB400 56.89%, #78E6A0 78.04%, #82E1FF 96.67%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 900!important;
        font-size: 20px!important;
    }
    .survey_btn{
        background: linear-gradient(270deg, #F40000 0%, #FF5A00 19.13%, #FF8700 36.75%, #FFB400 56.89%, #78E6A0 78.04%, #82E1FF 96.67%);
        padding: 20px;
        width: 85%;
        border-radius: 0 30px 30px 0;
        text-align: right;
        span{
            background: rgb(255, 255, 255);
            // background: #000;
            padding: 10px 20px;
            // color: #fff;
            color: #FF5A00;
            border-radius: 30px;
            font-size: 11px;
            font-weight: bolder;
        }
    }
}
.langRadioBtns{
    accent-color: black;
}
.price-with-off{
    display: flex;
    justify-items: center;
    align-items: center;
    .clrGreen{
        color: green;
        font-size: 1rem;
    }
}
@media screen and (max-width:767px) {
    .starcount{
        display: inline-flex;
        padding: 0;
    }
}
.mobile-code{
    position: relative;
    border-bottom: 1px solid;
    font-size: 13px;
}
.mobile-code::after{
    content: "";
    height: 15px;
    width: 15px;
    position: absolute;    
    right: 5px;
    top: 12px;
}
.mobile-code.profilepage::after{
    background: url(../images/icons/left-angle.png);
    background-size: 100% 100%;
    transform: rotate(-90deg); 
    background-repeat: no-repeat;
}
.mobile-code.homepage::after{
    background: url(../images/icons/back.png);
    background-size: 50% 90%;
    transform: rotate(-90deg);
    background-repeat: no-repeat;
    top: 9px;
    right: 2px;
}
.mobile-code.profilepage{
    font-weight: 600;
}
.login-faq{
    a{
        color: $white;
        text-decoration: underline;
    }
}
.contact-page{
    text-align: center;
    padding: 30px 20px;
    h5{
        font-size: 16px;
        font-weight: 900!important;
    }
    .contact-img{
        height: 130px;
        margin-bottom: 25px;
    }
    a{
        color: #000;
        font-weight: bold;
        text-decoration: underline;
    }

}
.Paybalance{
    .footer-nav{
        z-index: 999!important;
    }
}
.rewards-list{
    margin: 15px 0;
    h4.title{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .reward-banner{
        padding: 20px 0;
        background-color: #000;
        h5{
            font-size: 15px;
            color: #E5E5E5;
            position: absolute;
            bottom: 0;
        }
        p{
            color: #fff;
            margin-bottom: 0;
            margin-top: 20px;
        }
        p.redeem-now{
            margin-top: 15px;
            text-align: center;
            border: 2px solid;
            padding: 5px;
            border-radius: 25px;
        }
    }
}
p.redeem-now{
    margin-top: 15px;
    text-align: center;
    border: 2px solid;
    padding: 5px;
    border-radius: 25px;
    color: #fff;
}
.fix-header{
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    padding: 15px;
    background: #fff;
    z-index: 1;
}
.reward-expiry {
    padding: 15px;
    background-size: 100% 100%!important;
    h3{
        font-size: 16px;
    }
    h5{
        font-size: 15px;
        font-weight: 600!important;
        b{
            font-family: sans-serif;
        }
    }
    p{
        font-size: 14px;
        margin-bottom: 0;
        font-weight: 600;
    }
}