@import "./variables.scss";
.carousel *{
    vertical-align:text-top;
}
.carousel-slider{
    height: 100vh;
}
.custom-slider{
    max-width: 360px;
    height: 100vh;
    margin: 0 auto;
    .custom-slider-item{
        margin-top: 80px;
        height: 100vh;
        .slider-count{
            position: relative;
            height: 100px;
            margin-bottom: 40px;
        }
        .slider-count::after{
            position: absolute;
            height: 80px;
            right: 0px;
            font-size: 50px !important;
            color: transparent;
            font-weight: bold;
            -webkit-text-stroke-width: 0.1rem;
            font-family: 'TCCC-UnityHeadline Regular';
            -webkit-text-stroke-color: #333;
            text-align: left;
            padding-left: 15px;
        }
        .slider-heading{
            width: 100%;
            margin: 0;
            height: 100px;
            td{
                // padding-left: 15px;
            }
            img{
                width: 45px!important;
                height: 45px!important;
            }
            span{
                font-size: 24px;
                font-weight: bold;
                line-height: 30px;
            }
        }
        .slider-desc{
            width: 270px;
            margin: 50px auto 20px;
            padding: 0 30px;
        }
    }
}
.carousel-status{
    display: none;
}
.control-dots{
    height: 50px;
    // z-index: 0!important;
    top: 25px;
    .dot{
        background-color: $black!important;
    }
}

.slider-one::after {  
    content: "01";
    background: rgb(130, 225, 255);
    background: linear-gradient(90deg, rgba(130, 225, 255, 1) 0%, rgba(130, 227, 150, 1) 100%);
    width: 90%;    
    border-radius: 100px 0 0 100px;
}

.slider-two::after{
    content: "02";
    background: rgb(140, 223, 152);
    background: linear-gradient(90deg, rgba(140, 223, 152, 1) 0%, rgba(255, 167, 1, 1) 100%);
    width: 100%;
}
.slider-three::after{
    content: "03";
    background: rgb(255, 167, 1);
    background: linear-gradient(90deg, rgba(255, 167, 1, 1) 0%, rgba(255, 101, 1, 1) 100%);
    width: 100%;
}
.slider-four+.slider-heading{
    // width: 290px!important;
}
.slider-four::after{
    content: "04";
    background: rgb(255, 101, 1);
    background: linear-gradient(90deg, rgba(255, 101, 1, 1) 0%, rgba(247, 23, 1, 1) 100%);
    width: 90%;
    border-radius:0 100px 100px 0;
    left: 0;
}
.font_create_account {
    font-size: 14px;
    line-height: 21px;
    color: #010101;
    font-weight: normal;
    width: 250px;
    margin: 0 auto;
    height: 100px;
}
.text-start {
    text-align: left!important;
}
.custom-slider-item .skip{
    bottom:25%;
    color: #000;
}
@media screen and (max-width:767px) {
    .custom-slider{
        max-width: 100%;
    }
    .splash_number1 {
        position: absolute;
        border-radius: 100px 0px 0px 100px;
        text-align: left;
    }
    // .custom-slider-item .theme-btn{
    //     bottom:30%;
    // }
    .custom-slider-item .skip{
        bottom:25%;
        color: #000;
    }
}

@media screen and (max-width:992px) {
    .custom-slider{
        max-width: 100%;
    }
}