/* .popup {
    position: fixed;
    width: 450px;
    height: 20%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto
}

@media screen and (max-width:767px) {
    .popup{
        width: 90%;
    }
}
.modal-content{
    border: 1px solid rgb(244 0 0);
}
.btn-theme{
    background: #F40000;
    color: #ffffff;
}
.popupinner {
    position: absolute;
    left: 2%;
    right: 2%;
    top: 2%;
    bottom: 2%;
    margin: auto;
    border-radius: 20px;
    background: white;
}

div.modal-footer {
    text-align: center;
} */