
// .history-sec-box {
// height: calc(100vh - 200px);
// overflow-y: scroll;
// }
.list-box{
  padding: 0;
  margin: 0;
}


.history-sec-box .list-box li{
    position: relative;
    padding: 20px 0;
}
.history-sec-box .list-box li:before{
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: .5px;
  background-color: #BABABA;
  left: 0;
  transform: rotate(0deg);
}

.history-sec-box .list-box li:after {
  content: '';
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 5px;
  height: 5px;
  // background-color: #BABABA;
  border-radius: 50%;
}
    
.history-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.history-box .history-detail{
  text-align: left;
  width: calc(100% - 60px);
}
.history-box .history-detail .history-date{
    font-size: 12px;
    color: #BABABA;
    font-weight: 600;
    margin-bottom: 0;
}
.history-box .history-detail .history-title{
    font-size: 14px;
    font-weight: 600!important;
    color: #000000;
    margin-bottom: 0;
}
.history-box .history-detail .history-order{
    font-size: 12px;
    font-weight: 600;
    color: #BABABA;
    margin-bottom: 0;
}
.history-box .history-price{
  text-align: right;
   width: 80px;
}
.history-box .history-price .price-detail{
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  
}
.history-box .history-price .history-msg{
  color: #BABABA;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}
.history-box .cart-img{
    width: 60px;
    text-align: left;
}