@import "../../assets/scss/_variables.scss";

span.input-group-text {
    background: none;
    border: none;
}

// .iAccepted input {
//     padding: 0;
//     height: initial;
//     width: initial;
//     margin-bottom: 0;
//     display: none;
//     cursor: pointer;
//   }
  
  .iAccepted label {
    // cursor: pointer;
    width: 85%;
  }
    .policy-checkbox{
        position: absolute;
        width: 1em;
        height: 1em;
        margin-left: 0!important;
        margin-top: 0.25em;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 1px solid rgba(0,0,0,.25);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-print-color-adjust: exact;
        right: 25px
    }
    .policy-checkbox:checked {
        background-position: right center;
        background-image: url(../../_assets/images/icons/success.png);
    }
    .policy-checkbox:checked[type=checkbox] {
        background-image: url(../../_assets/images/icons/success.png);
    }
    .policy-checkbox{
        background-color: #000;
        border-color: #fff;
    }
    .policy-checkbox:checked {
        border-color: #fff;
    }
//   .iAccepted label::before {
//     content:'';
//     -webkit-appearance: none;
//     background-color: transparent;
//     border: 1px solid #fff;
//     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
//     padding: 8px;
//     display: inline-block;
//     position: absolute;
//     right: 10px;
//     top: 50%;
//     transform: translateY(-50%);
//     vertical-align: middle;
//     cursor: pointer;
//     margin-right: 5px;
//   }
  
//   .iAccepted input:checked + label:after {
//     content: '';
//     display: block;
//     position: absolute;
//     top: 50%;
//     right: 21px;
//     width: 5px;
//     height: 10px;
//     border: solid #ffffff;
//     border-width: 0 2px 2px 0;
//     transform: translateY(-50%) rotate(45deg)
//   }
//   .iAccepted input:checked + label.smsMarkingSMS:after{
//       top: 44%!important;
//       right: 23px ;
//   }
//   .iAccepted input:checked + label.mailMarkingSMS:after{
//     top: 44%!important;
//     right: 23px ;
// }
input.form-control {
    border: none;
    background: none;
}
.form-group{
    label{
        margin-bottom: 0;
    }
}

.LineInput{
    .input-group{
        position: relative;
        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: .5px;
            background-color: $grey;
            left: 0;
            transform: rotate(0deg);
        }
        // &:before{
        //         content: '';
        //         position: absolute;
        //         bottom: 1px;
        //         right: 0;
        //         width: 5px;
        //         height: 5px;
        //         background-color: #BABABA;
        //         border-radius: 50%;
        // }
    }
    
}

.iAccepted a{
    text-decoration: none;
    color: #ffffff;
}
.ant-picker{
    border: none!important;
    background: transparent!important;
}
.ant-picker-input input{
    color: $theme_text_color!important;
    font-weight: 600 !important;
    font-size: 1rem!important;
    
    height: calc(1.5em + 0.75rem + 2px);
}
.ant-picker-input input::placeholder{
    color: #ddd!important;
    ;
}
.ant-picker.form-control{
border-bottom: 1px solid $theme_text_color!important;
height: calc(1.5em + 0.75rem + 4px);;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
    color: #fff;
    background: #f40000!important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border: 1px solid #f40000!important;
}
.anticon {
    font-size: 1.6rem;
    margin-right: -10px;
    color: #000!important;
}
@media (max-height:768px) {
    // .whitebody {
    //     // height: 300px;
    //     // overflow-y: scroll;
    // }
    .iAccepted input:checked + label.smsMarkingSMS:after{
        top: 47%!important;
        right: 21px ;
    }
}