.whitebody-popup.saferpayModal{
    width: 98%!important;
    bottom: 0!important;
    top: unset!important;
    max-height: 95vh!important;
    min-height: 40vh!important;
    border-radius: 25px!important;
    transform: translate(-50%, 0)!important;
    margin: 0 auto;
    .title {
        margin-top: -4px;
        h4{
            font-size: 14px;
        }
    }
    .saferclose{
        height: 25px;
        width: 25px;
        background-color: #000;
        text-align: center;
        border-radius: 25px;
        display: inline-block;
        img{
            height: 14px;
            margin-top: -3px
        }
    }
    .paymentMethodList{
        width: 95%;
        max-height: calc(100vh - 150px);
        overflow-y: scroll;
        .addCreditCard{
            // width: 95%;
            display: flex;
            background-color: black;
            color: white;
            border-radius: 40px;
            justify-content: space-between;
            position: relative;
            label{
                margin-bottom: 0;
                padding: 5px 0;
            }
            span{
                padding: 5px;
                img{
                    height: 35px;
                }
                p{
                    display: inline;
                    padding-left: 10px;
                    font-size: 14px;
                }
            }
            input[type=radio]{
                // accent-color: white;
                // -webkit-appearance: none;
                // margin-right: 10px;
                width: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right:10px
            }
        }
        .btn-addcreditcard{
            background-color: black !important;
            font-weight: 500;
            color: white;
        }
        .paymentMethodClass{
            border: 1px solid #D9D9D9;
            border-radius: 18px;
            .payment-currency{
                font-size: 20px;
                font-weight: 600;
            }
            .payment-amount{
                margin-left: 5px;
                font-size: 28px; 
                color:#36C54C
            }
            .purchase-history{
                font-size: 17px;
                font-weight: 600;
            }
            div{
                .payment-desc-heading{
                    font-size: 16px;
                    font-weight: 600;
                }
                .payment-desc-content{
                    font-size: 13px;
                    font-weight: 300;
                }
            }
        }
    }
    
}