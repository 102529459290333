.surver-error{
    position: relative;
    background-color: #f0f0f0;
    height: 100vh;
    h4{
        color: red;
        color: red;
        font-size: 20px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        text-align: center;
        padding: 10px;
    }
}
.survey-form{
    padding: 10px 15px;
    min-height: 100vh;
    background-color: #000;
    color: #fff;
    .nowrap{
        white-space: nowrap;
        padding: 0.2rem .4rem!important;
        text-align: center;
    }
    .survey-back{
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
    label{
        display: initial!important;
    }
    .table {
        color: #fff!important;
        th,td{
            border: none;
            padding:0.2rem;            
        }
        td{
            vertical-align: middle;
        }
        .ratinghead{
            white-space: wrap;
            vertical-align: middle;
            font-size: 12px;
        }
        .radio_size{
            font-size: 12px;
        }
    }
}
.table-responsive::-webkit-scrollbar {
    display: inherit;
    height: 6px;
}
.table-responsive::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.4); 
    border-radius: 8px;
    -webkit-border-radius: 8px;
}
.table-responsive::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(100,100,100,0.8); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
@media(max-width:767px){
    .table-responsive{
        width: 100%;
        overflow-x: scroll;
    }
}
@media(max-width: 350px){
    .table_radio td.d-sm-flex{
        display: inline-flex;
    }
}