.CartProduct .product-box{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
    padding: 15px 20px;
    border-radius: 20px;
    position: relative;
    box-shadow: 0 25px 25px rgba(0,0,0,0.1);
    min-height: 70px;
    background-color: #fff;
}
.CartProduct .product-box .productimg{
    position: absolute;
    left: 10px;
    bottom: 10px;
}
.CartProduct .product-title{
    width: calc(100% - 100px);
    padding:0 10px 0 30px;
    font-weight: bold;
    font-size: 13px;
}
.CartProduct .product-oldprice, .CartProduct .product-price{
width: 80px;
font-weight: bold;
padding: 0 5px;
}
.addProduct{background: rgba(244, 0, 0, .1); color: #f40000; font-weight:600; padding: 3px 20px 6px; font-size: 14px; border-radius: 25px; }
.removeproduct{
    background: #000;
    color: #fff;
    padding: 18px 10px;
    height: 50px;
    font-size: 9px;
    border-radius: 10px;
    border: 0;
    outline: 0 !important;
}

.cooler-product input.form-control.input-number{
    height: 20px;
    font-size: 13px;
  }
  button.btn.bgRed.btn-number {
      padding: 0;
      width: 20px;
      line-height: 1;
      height: 20px;
      color: white;
      opacity: 1;
  }
  .pro-old {
    max-width: 50px !important;
}
.maxwidth65{
  max-width: 65px !important;
}
.pro-count {
    max-width: 110px !important;
    padding: 0px 5px;
}

.CartProduct {
    min-height: calc(100% - 250px);
}
.ProductListing {
    height: 100%;
    .container{
        height: 100%;
        .row{
            height: 100%;
        }
    }
}
.containercheck {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .containercheck input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 4px;
    left: -7px;
    height: 20px;
    width: 20px;
    background-color:white;
    border: 1px solid black;
  }

  .containercheck input:checked ~ .checkmark {
    background-color: #F40000;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .containercheck input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .containercheck .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .product-title span.remove{
    position: absolute;
    top: 5px;
    right: 10px;
    background: #f40000;
    color: #fff;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 1.7;
    border-radius: 50%;
    font-size: 12px;
    cursor: pointer;
    @media (max-width:374px){
      right: 40%;
    }
  }