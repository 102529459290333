
@import "../../_assets/scss/variables";

// .vBtn{
//     min-width: 160px;
//     color: #fff !important;
//     padding: 15px 30px;  
//     box-shadow: 0px 10px 40px rgba(0,0,0,0.2);
//     border-radius: 20px;
//     border: 0;
//     span.text{
//         float: left;
//         font-weight: 600;
//         padding-right: 20px;
//     }
//     span.icon{
//         float: right;
//     }
//     span.text-center{
//         text-align: center;
//     }
// }
// .vBtnRed{
//     background:$red;
// }
    
// .vBtnBlack{
//     background: $black;
// }

.btn-theme-lg{
    background-color: $white;
    width: 100%;
    border-radius: 20px;
    font-weight: 600;
}