@import "../../assets/scss/variables";


.Payment{
    background-color: $white;
    position: relative;
    height: 100%;
    &:before{
        content: '';
        position: absolute;
        height: 100%;
        background-color: #2e2f30;
        width: 100%;
        left: 0;
        top: 0;
        
    }
    .payment-bg{
        background-color: $white;
        min-height: 300px;
        height: auto;
        position: relative;
    }
    
    .payment-title{
        font-weight: 600;
        font-size: 18px;
    }
    .btn-blue-sec{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .btn{
            min-width: auto;
            width: 100%;
            display: block;
            color: #fff !important;
            padding: 10px 30px;
            box-shadow: none;
            border-radius: 50px;
            border: 0;
            background-color: #5981c1;
        }
    }
  
    .popup-box .whitebody-popup{
        min-height: auto;
    }
   
    .popup-button{
        .btn  {
            background-color: transparent;
            color: #a1d9f2!important;
            font-weight: 600;
            font-size: 20px;
            padding: 0;
        }
    }
    .popup-title{
        border-bottom: 1px solid #bababa;
        padding-bottom: 10px;
        margin: 0 0 10px 0!important;
    }

}