.whitebox {
    padding: 10px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 30px 50px rgba(0,0,0,0.1);
    margin-bottom: 5px;
    .profileImage{
        img{
            // box-shadow: 0 10px 20px #BABABA;
            // border-radius: 11px;
        }
    }
    .profileName{padding-left: 5px;font-size: 16px;overflow: hidden;width: 187px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-overflow: ellipsis; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
}
