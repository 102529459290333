@import './variables';
// @import url(db.onlinewebfonts.com/c/47fd6d6802649d99e58c8185171263b4?family=TCCC-UnityText);
@font-face {
    font-family: "TCCC-UnityHeadline Regular";
    src: url(../fonts/tcc/tccc-unityheadline/2def107af3e4eeb88b5ca50c3320ae0a.eot); /* IE9*/
    src: url(../fonts/tcc/tccc-unityheadline/2def107af3e4eeb88b5ca50c3320ae0a.eot?#iefix) format("embedded-opentype"), /* IE6-IE8 */
    url(../fonts/tcc/tccc-unityheadline/2def107af3e4eeb88b5ca50c3320ae0a.woff2) format("woff2"), /* chrome、firefox */
    url(../fonts/tcc/tccc-unityheadline/2def107af3e4eeb88b5ca50c3320ae0a.woff) format("woff"), /* chrome、firefox */
    url(../fonts/tcc/tccc-unityheadline/2def107af3e4eeb88b5ca50c3320ae0a.ttf) format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url(../fonts/tcc/tccc-unityheadline/2def107af3e4eeb88b5ca50c3320ae0a.svg#TCCC-UnityHeadline&Regular) format("svg"); /* iOS 4.1- */
}
@font-face {font-family: "TCCC-UnityText Regular";
    src: url(../fonts/tcc/tccc-regular/7d3930158276067e79e7e9b281b78976.eot); /* IE9*/
    src: url(../fonts/tcc/tccc-regular/7d3930158276067e79e7e9b281b78976.eot?#iefix) format("embedded-opentype"), /* IE6-IE8 */
    url(../fonts/tcc/tccc-regular/7d3930158276067e79e7e9b281b78976.woff2) format("woff2"), /* chrome、firefox */
    url(../fonts/tcc/tccc-regular/7d3930158276067e79e7e9b281b78976.woff) format("woff"), /* chrome、firefox */
    url(../fonts/tcc/tccc-regular/7d3930158276067e79e7e9b281b78976.ttf) format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url(../fonts/tcc/tccc-regular/7d3930158276067e79e7e9b281b78976.svg#TCCC-UnityText&Regular) format("svg"); /* iOS 4.1- */
}
h1, h2,h3, h4, h5,h6{
    font-family: "TCCC-UnityHeadline Regular";
}

.main-page{
    width: 100%;
    overflow-x: hidden;
}
.theme-font{
    font-family: "TCCC-UnityHeadline Regular";
}
.top-Header{
    width: 100%;
    padding: 10px;
    .logo{               
        display: inline-block;
        img{
            height: 50px;
        }
    }
    
}
    .landing_title{
        font-family: "TCCC-UnityHeadline Regular";
        color: #000;
        padding: 25px 0;
        padding-left: 10%;
        font-weight: bolder;
    }
.MainSection{
    padding: 5rem 10rem;
    h4{
        
        font-size: 30px;
        font-weight: 800;
        margin-top: 10px ;
        margin-bottom: 0;
    }
    span{
        font-size: 18px;
    }
}
.headingdiv img{
    height: 50px;
}
.headingOption{
    display: none;
}
.headingOption button{
    padding: 10px 30px;
    color: $theme-color;
    border-radius: 25px;
    font-weight: 500;
}
.landing_sub_title{
    margin-top: 45px;
    padding: 20px;
    padding-left: 10%;    
    display: flex;
    img{
        margin-top: 16px;
    }
}
.position-none{
    position: unset!important
}
.landing_sub_title::before{
    content: '';
    position: absolute;
    left: 0%;
    height: 106px;
    background: linear-gradient(90deg, #82E1FF 3.58%, #78E6A0 22.85%, #FFB400 43.69%, #FF8700 62.27%, #FF5A00 89.9%, #F40000 100%, #F40000 100%, #F40000 100%);
    width: 95%;
    border-radius: 0 50px 50px 0;
    z-index: -1;
}
.phoneImage{
    position: absolute;
    height: 400px;
    right: 10%;
    // // background: url("../images/landing/icon/group-1.png");
    // background-size: contain;
    // background-position: 30px -30px;
    // transform: scale(2);
    // background-repeat: no-repeat;
    img{
        max-height: 650px;
        position: absolute;
        top: -75%;
        right: 15%;
    }
}
.features{
    padding-left: 10%;
    margin-top: 30px;
    h4{
        font-weight: 700;
    }
    li{
        font-size: 14px;
        color: #000;
        font-family: "TCCC-UnityText Regular";
    }
}
.continueweb{
    color: #000;
    margin-top: 10px;
    font-weight: 500;
    a{
        color: #000;
        text-decoration: underline;
    }
}
.mobgroup {
    margin-left: -15px;
}
.btn-landing{
    font-family: "TCCC-UnityText Regular";
    border: 2px solid;
    width: 210px;
    border-radius: 30px;
    font-weight: bold;
}
.btn-top{
    margin-left: 15px;

}
.mob-landing-footer {
    padding: 40px 20px;
    text-align: center;
    background: linear-gradient(90.36deg, #82E1FF 0.3%, #78E6A0 99.69%);
    .mob-button{
        font-family: "TCCC-UnityText Regular";
        border: 2px solid;
        width: 210px;
        border-radius: 30px;
        font-weight: bold;
        background-color: #000;
        color: #fff;
        border-color: #000;
        cursor: pointer;
    }
}

.contentlist{
    margin-left: 0;
    list-style: none;
    li{
        position: relative;
        margin-bottom: 10px;
        &::before{
            content: '';
            position: absolute;
            height: 20px;
            width: 20px;
            background: url(../images/landing/Frame.png);
            left: -30px;
            background-size: 20px 17px;
            background-repeat: no-repeat;
            top: 4px;
        }
    }
    
}

.cokedesc{
    z-index: 99;
    h3{
        font-weight: 600;
    }
}
.footer{
    min-height: 200px;
    padding: 30px;
    background-color: #000000;
    .footerList{
        display: inline-block;
        li{
            list-style: none;
            float: left;
            padding: 10px 20px ;
            position: relative;
            a{
                color: #fff;
                text-transform: uppercase;
            }
        }
    }
}
.footerList li::after{
    content: '|';
    position: absolute;
    right: 0;
    color: #fff;
}
.footerList li:last-child::after{
    display: none;
}
.download{
    h6{
        font-weight: bolder;
    }
}
.bg-grey{
    background-color: #eeeeee;
}
.basic_details_flex {
    display: flex;
    flex-wrap: nowrap;
    // margin-bottom: 30px;
    padding: 25px;
    img{
        height: 100px;
        width: 100px;
    }
  }
  .basic_details_flex > div {
    width: 100%;
    margin: 0px 20px 0px 0px;
    text-align: center;
  }
  .basic_details_flex > div:last-child {
    margin-right: 0px;
  }
  .basic_details_flex > div > a {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    line-height: 25px;
  }
  .basic_details_flex > div > a > img {
    display: block;
    margin: 0 auto;
  }
  .basic_details_flex > div > a > span {
    display: block;
    font-size: 50px;
    color: #eeeeee;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #333;
    margin: 30px 0px;
    padding: 0px;
    text-decoration: none;
    font-family: verdana;
    font-weight: bold;
  }
@media(max-width:767px){
    .headingOption{
        display: block;
    }
    .MainSection{
        padding: 3rem 1rem;
    }
    
    .headingdiv img{
        .title{
            padding: 25px 10px;
            font-size: 25px;
            text-align: center;
        }
        height: 30px;
    }
    .phoneImage{
        position: relative;
        height: 350px;
        background-size: contain;
        background-position: center -10px;
        transform: scale(2);
        z-index: 0;
    }
    .landing_title{
        font-size: 28px;
        text-align: center;
        padding: 20px;
    }
    .cokedesc{
        margin-top: 140px;
    }
    .coketitle{
        padding-left: 25px!important;
    }
    .download{
        position: relative;
        z-index: 2;
        text-align: center; 
        img{
            height: 35px;
        }
    }
    .cokelogo img{
        height: 70px;
    }
    .footer {
        padding: 50px 10px;
        .footerList{
            display: inline-block;
            padding-left: 0;
            li{
                float: none;
                display: block;
                text-align: center;
                border-bottom: 1px solid #fff;
                ::after{
                    display: none;
                }
                &:last-child{
                    border: none;
                }
            }
            
        }
        .footerList li::after{
            display: none;
        }

    }
    .basic_details_flex > div {
        width: 80%;
        margin: 10px auto;

      }
      .basic_details_flex {
        display: block;
        padding: 20px;
      }
      .basic_details_flex > div:last-child{
        margin-bottom: 0;
        margin-right: auto;
      }
}

@media(max-width:552px) {
    .phoneImage{
        height: 300px;
        transform: scale(2.3);
        background-position: center 10px;
    }
    .cokedesc{
        margin-top: 180px;
        h4{
            font-weight: bold;
            margin-bottom: 10px;
        }
        h6{
            font-size: 16px;
            font-weight: bold;
        }
    }
}

@media(max-width:374px) {
    .phoneImage{
        height: 200px;
        transform: scale(2);
        background-position: center 10px;
    }
    .cokedesc{
        margin-top: 120px;
    }
}