.whitebody-popup{
    background: #fff;
    box-shadow: 0 25px 50px rgba(0,0,0,0.1);
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
}
.popup-box{
    text-align: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.1);
        .whitebody-popup{
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            min-height: 280px;
            .popup-title{
                font-size: 18px;
                margin: 30px 0 50px 0;
                    span{display: block;}
            }
            .popup-btn{
                margin-top: 10px;
                width: 200px;
                border: 2px solid #000;
                border-radius: 20px;
                box-shadow: none;
                font-weight: 500;                
            }
            .popup-btn-sm{
                margin-top: 10px;
                width: auto;
                min-width: 100px;
                border: 2px solid #000;
                border-radius: 20px;
                box-shadow: none;
                font-weight: 500; 
            }
            .popup-btn:hover{
                background-color: #000;
                color: #fff;
            }
            .circle-box{
                margin-top: -100px;
            }
        }
    }