@import "../../assets/scss/variables";
.heading{
    padding: 10px 15px;
    width: 100%;
    .title{
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        text-align: center;
        line-height: 1.5;
    }
    .cancel,.question{
        color: #fff;
        font-size: 16px;
    }
    .question{
        height: 20px;
        width: 20px;
        line-height: 1;
        margin-top: 6px;
        text-align: center;
        border: 1px solid #fff;
        border-radius: 50%;
    }
}
.backImg{
    height: 16px;
}
.largeBackImg{
    height: 22px;
}
.rightImg{
    height: 20px;
}
.closeImg{
    height: 20px;
}