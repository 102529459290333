.SliderBoxs{width: 100vw;}
.scanQrCode{width:100%;
    padding: 20px 0;
    .buttonRed{width: 100%;
        .redBox {
            padding: 18px;
            background: #F40000;
            border-radius: 20px;
            box-shadow: 0 30px 50px rgba(0,0,0,0.1);
            margin-bottom: 5px;
            float: right;
            .ScanName{padding-left: 10px; font-size: 16px; font-weight: bold; color: #fff!important;}
        }
    }

}


.Product-listing-data{
    height: 100%;
    
    .product-count{height: 40px;
        @media (max-width:374px){
            font-size: 14px;
        }
    }
    .scanQrCode{height: 100px;}
    .heroSlider{
        height: calc(100% - 15px); 
        // margin: 0 -20px;
        
        .swiper-slide{
            height: 100%;
            position: relative;
            // padding: 20px;
            overflow: hidden;
            .product-list-box{
                
            background: #fff; 
            // padding:20px 20px 20px; 
            height: 100%;
            width: 100%;
            // border-radius: 15px; 
            box-shadow: 0 10px 20px rgba(0,0,0,0.1);
            overflow-y: scroll;
            padding: 0px 10px 120px 10px;
            .cooler-id{
                position: absolute; 
                background: #fff; 
                width: calc(100% - 40px); 
                border-radius: 30px 30px 0 0; 
                left:20px; 
                padding: 20px 20px 0px; 
                top:0;
                z-index: 99;}
            }
        }
    }

}
.slideActivation, .swiper-container, .swiper-wrapper{height: 100%;}


.slideActivation .product-box{
    align-items: center;
    position: relative;
    min-height: 90px;
    border-bottom: solid 1px rgba(172, 172, 172, 0.5);
}

.slideActivation .product-title{
    
    font-weight: bold;
    @media (max-width:374px) {
        font-size: 12px;
        
        line-height: 1.3;
        // width: calc(100% - 80px);
    }
}
.slideActivation .product-oldprice, .slideActivation .product-price{
// width: 65px;
font-weight: bold;
padding: 0 5px;
@media (max-width:374px) {
    font-size: 12px;
    // width: 40px;

}
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    position: fixed;
    top: 150px;
    height: 40px;
    @media (max-width:374px) {
        top: 148px;
    }
}
.swiper-pagination-bullet-active{background: #000 !important;}


.heroSlider {
    .htSwiperButtonNav {
        font-size: 40px;

        width: 50px;
        height: 50px;
        padding: 0;

        color: #FFF;
        border: none;
        background: none;

        &:after {
            font-size: 0;
        }
    }

    .swiperButtonPrev {
        left: 50px;
    }

    .swiperButtonNext {
        right: 50px;
    }
}

.sliderOne {
    .sliderFixedHeight {
        height: 900px;
    }
    .slide {
        padding-top: 100px;
    }
}

.graBg1 {
    background: linear-gradient(to top, #1344A4, #011054);
}

.slide {
    .content {
        h1 {
            line-height: 65px;

            margin-bottom: 25px;

            animation: Gradient 5s ease infinite;

            color: #FFFFFF;
            background: linear-gradient(110deg, #FFF 33%, rgba(0, 0, 0, 0) 33%),
            linear-gradient(110deg, #F2A192 34%, #F06850 34%);
            
            background-size: 400%;

            -webkit-text-fill-color: transparent;
        }
        p {
            font-size: 16px;
            line-height: 26px;

            margin-bottom: 40px;

            color: #EEF3F6;
        }
    }
    .slideFrontImg {
        text-align: right;
    }
}

// Responsive Css



@keyframes Gradient {
    0% {
        background-position: 30% 50%;
    }
    50% {
        background-position: 25% 50%;
    }
    100% {
        background-position: 30% 50%;
    }
}
