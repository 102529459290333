.decouple-promo{
    margin-bottom: 15px;
    width: 100%;
    .img-placeholder{
        height: 150px;
        background: #ccc;
        position: relative;
        // background-position: 400% 100%;
        // animation-name: image-sketon ;
        // animation-duration: 2s;
        // animation-timing-function: ease-in-out;
        // animation-iteration-count: infinite;
    }
    img{
        max-width: 100%;
    }
}
@keyframes image-sketon{
    0%{
        background: #eee;
    }
    100%{
        background:#f6986a
    }
}