@import '../../assets/scss/variables';
span.input-group-text {
    background: none;
    border: none;
}

input.form-control {
    border: none;
    background: none;
}
.seepassword{
    position: absolute;
    bottom: 5px;
    right: 0px;
}
.ArrowBtn{
    position: fixed;
    right: 15px;
    bottom: 20px;
    .loginText{
        color: $theme_text_color;
        margin-right: 10px;
    }
    img{
        height: 40px;
        padding: 10px;
        border-radius: 50%;
        background-color: $theme_text_color;
    }
 }
.btnSign{
    // position: fixed;
    // right: 15px;
    // bottom: 20px;
    .loginText{
        color: $theme_text_color;
        margin-right: 10px;
    }
    img{
        height: 40px;
        padding: 10px;
        border-radius: 50%;
        background-color: $theme_text_color;
    }
 }
 input:-webkit-autofill,
 input:-webkit-autofill:hover,
 input:-webkit-autofill:focus,
 input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px black inset !important;    
    box-shadow: 0 0 0 30px black inset !important;
    -webkit-text-fill-color: white;  
 }
 @keyframes input_background_autofill {
     100% { background-color: transparent }
 }