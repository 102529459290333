@import "../../assets/scss/variables";
.splashscreen{
    width:100vw; 
    height:100vh; 
    position:fixed; 
    top:0; left: 0; 
    z-index: -99; 
    animation: animation 1 3s linear;
    opacity: 0;
    background-color: $theme-color;
    img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: auto;
        height: 100vh;
        object-fit: cover;
    }
}

@media screen and (max-width:767px) {
    .splashscreen{
        img{
            width: 100%;
            height: 100vh;
        }
    }
}

@keyframes animation{
    0%{opacity: 1;  z-index:99999;}
    90%{opacity: 1;}
    99%{opacity: 0;}
    100%{opacity: 0; z-index:-99;}
}