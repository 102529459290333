@import '../../assets/scss/variables.scss';
// .bm-burger-button{
//     position: relative;
//     z-index: 99!important;
//     width: 70px;
//     height: 70px;
//     display: block;
//     background: url(../../assets/images/nav/menuIcon.svg) no-repeat center center;

//         span{
//             display: none;
//         }
// }

// .bm-menu-wrap{
//     top: 0;
//     height:100%; 
//     width: 40% ;
//     padding: 0 !important;
//     transition: all 0.5s ease 0s;
//     .bm-menu{    
//         overflow: auto;
//         background:$theme-color;
//         // border-radius: 20px;
//         padding:20px;
//         // box-shadow: 15px 25px 25px #BABABA;
        
//         a.menu-item{
//             padding: 15px 0;
//             font-weight: bold; 
//             color:#fff;
//             width: 100%;
//             position: relative;
//             &:after{
//                 position: absolute;
//                 content: '';
//                 width: 20px;
//                 height: 20px;
//                 background: url(../../assets/images/icons/arrow.png);
//                 background-repeat: no-repeat;
//                 background-position: right;
//                 background-size: 20px 20px;
//                 right: 0;
//             }
//             .menu-name{
//                 // padding: 20px 10px;
//                 font-weight: 400;
                
//             }
//         }
//     }
// }

// .bm-overlay{
//     background: #0000007a !important;
// }
// .ant-modal-mask,.ant-modal-wrap{
//     z-index: 10000!important;
// }

// @media(max-width:767px){
//     .bm-menu-wrap{
//         width: 80%;
//     }
// }

.navigation{
    position: fixed;
    left: -100%;
    width: 40%;
    top: 0;
    background-color: #fff;
    transition: .5s all;
    transform-origin: left;
    height: 100vh;    
    z-index: 10;
    ul{
        padding: 10px;
        li{
            border: none!important;
            border-bottom: 2px solid #D2D2D2!important;
            padding-left: 0px;
            position: relative;   
            ::after{
                content: '';
                height: 15px;
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACDSURBVHgB5ZTbCYAwDEWvj0E6ipuYTWw3cRTdwBHcpLaQQumHJpo/D1zSfORAoASQQxwTKCVyFhhAlfBWOkDGkdKlTNyXur8VZjaJVCMUSbXCR+nIDaU46DirGc81FFk0CvUwJq+88ttBx9zMhMqlJn/selWPD/xERpayVuhhBEFwYC8tXD6iMqtkhgAAAABJRU5ErkJggg==);
                position: absolute;
                right: 0;
                top: 20px;
                width: 15px;
                background-repeat: no-repeat;
                background-size: 100%;
            }         
            a{
                font-size: 15px;
                color: #000;
                width: 100%;
                
            }
            span{
                font-size: 15px;
                color: #000;
            }
        }
        li:last-child{
            border-bottom: none!important;
        }
    }
}
.navigation.openMenu{
    left: 0!important;
}


@media screen and (max-width:992px) {
    .navigation{
        width: 100%;
        max-height: calc(100vh - 70px);
    }
}
@media screen and (max-width:767px) {
    .navigation{
        width: 100%;
        max-height: calc(100vh - 70px);
    }
}